;(function($){

    function Slides() {
   	 	var _ =  this;

   	 	$(".slider-home").slick({
       	autoplay: true,
       	arrows:false,
       	dots:false,
      });

      $(".slide-partners").slick({
        infinite:true,
        arrows:true,
        dots:false,
        slidesToShow: 6,
        slidesToScroll: 6,
        prevArrow: $(".arrow-left"),
        nextArrow: $(".arrow-right"),
        responsive: [
          {
            breakpoint: 769,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              }
          },
          {
            breakpoint: 640,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
          }
        ]
      });

      // $(".carrosel-lineproducts").slick({
      //   infinite:true,
      //   arrows:true,
      //   dots:false,
      //   slidesToShow: 3,
      //   slidesToScroll: 3,
      //   responsive: [
      //     {
      //       breakpoint: 769,
      //         settings: {
      //           slidesToShow: 2,
      //           slidesToScroll: 2,
      //         }
      //     },
      //     {
      //       breakpoint: 640,
      //         settings: {
      //           slidesToShow: 1,
      //           slidesToScroll: 1,
      //         }
      //     }
      //   ]
      // });

      

      $(".slider-line-product").slick({
        autoplay: true,
        arrows:false,
        dots:false,
      });
    }
	
   	new Slides();

}(jQuery));
 
 
 
 
