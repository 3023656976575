;(function($){

    function Navigation() {
   	 	var _ =  this;

   	 	//Events
        $(document).on("click", ".btn-menu, .btn-close", function(e){
            e.preventDefault();
            _.navInit();
        });

        $('a[href*="#"]:not([href="#"])').click(function() {
            _.navInative();
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              var hash = this.hash, 
              target   = $(hash);
              target   = target.length ? target : $('[name=' + this.hash.slice(1) +']');

              if (target.length) {
                $('html, body').animate({
                  scrollTop: target.offset().top
                });
                return false;
              }
            }
        });

        if($(".alert-captcha").length){
          swal("O Captcha não confere!");
        }
        if($(".alert-success").length){
          swal("Contato enviado com sucesso!", "Em breve nosso atendimento retornará o seu contato.", "success")
        }
        if($(".alert-mime").length){
          swal("Oops...", "Arquivos Válidos DOC e DOCX!", "error")
        }
        
    }

   	Navigation.prototype.navInit =  function() {
        var _ =  this;
        _.navIsActive() ? _.navInative() : _.navActive();
    };

    Navigation.prototype.navIsActive =  function() {
        return $("body").hasClass('navIsActive');
    };

    Navigation.prototype.navActive =  function() {
        $("body").addClass('navIsActive');
    };

    Navigation.prototype.navInative =  function() {
        $("body").removeClass('navIsActive');
    };

   	new Navigation();

}(jQuery));
 
 
 
 
